<template>
  <div>
    <v-container fluid class="py-6">
      <v-row class="my-5">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
              <div class="px-6 py-6">
                  <h3 class="text-h5 font-weight-bold text-typo">Script Settings (Ahrefs Creds)</h3>
              </div>
              <div class="px-6 pb-6 pt-0">
                  <v-row>
                      <v-col>
                          You can change Ahrefs credentials here.
                          <br>
                          This information is sensitive, so we don't display current credentials.
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="6">
                          <v-text-field
                              color="#e91e63"
                              label="Email"
                              placeholder="Email"
                              class="font-size-input input-style"
                              v-model="form.email"
                              outlined
                              dense
                          >
                          </v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                              color="#e91e63"
                              label="Password"
                              placeholder="Password"
                              class="font-size-input input-style"
                              v-model="form.password"
                              outlined
                              dense
                          >
                          </v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-btn
                              color="#cb0c9f"
                              class="
                        font-weight-bolder
                        btn-default
                        bg-gradient-default
                        py-4
                        px-8
                        ms-auto
                        mt-sm-auto mt-4
                      "
                              small
                              @click="save()"
                          >
                              Save
                          </v-btn>
                      </v-col>
                  </v-row>
              </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axiosIns from '../../../plugins/axios';

export default {
  name: "ScriptSettings",
  components: {
  },
  data: () => {
      return {
          form: {
              email: '',
              password: ''
          },
      };
  },
  computed: {
  },
  beforeMount () {
  },
  methods: {
      async save() {
          axiosIns.post('/api/config/ahrefs', {
              email: this.form.email,
              password: this.form.password,
          }).then((result) => {
              this.form.email = '';
              this.form.password = '';
          });
      }
  }
};
</script>
